<div class="help-center-nav" *ngIf="sharedService.globalFlages.showHelpCenterNav" [@fadeOutOnLeave] [@fadeInOnEnter]>
  <span class="text"> If you need any further help you can contact our supports from here. </span>
  <span class="icon">
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="19" y="0.615234" width="26" height="26" transform="rotate(45 19 0.615234)" fill="#00E096" />
    </svg>
  </span>
</div>
<div class="tutorials-nav" *ngIf="sharedService.globalFlages.showTutorialNav" [@fadeOutOnLeave] [@fadeInOnEnter]>
  <span class="text"> All product tutorials are available here in case you need them in the future. </span>
  <span class="icon">
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="19" y="0.615234" width="26" height="26" transform="rotate(45 19 0.615234)" fill="#00E096" />
    </svg>
  </span>
</div>
<div class="d-flex flex-row justify-content-end align-items-center help-wrapper">
  <span class="icon video" (click)="toggleVideoList()"><mat-icon> video_library</mat-icon> Tutorials</span>
</div>
<nb-card class="videos-list" *ngIf="showVideosList">
  <nb-card-body>
    <ngx-video-item
      *ngFor="let item of videosList; let i = index"
      [haveNoBorder]="videosList.length === i + 1"
      [item]="item"
      (playingIdChanged)="handleListLoad($event)"
    ></ngx-video-item>
  </nb-card-body>
</nb-card>
